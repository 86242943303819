export const CSV_REPORT_TYPE_OPTIONS = [
  {
    value: 'patients/income-received-summary',
    label: 'Income received summary',
  },
  {
    value: 'patients/current-patients-detail',
    label: 'Current patients detail',
  },
  {
    value: 'patients/patients-joining',
    label: 'Patients joining',
  },
  {
    value: 'patients/patients-leaving',
    label: 'Patients leaving',
  },
  {
    value: 'patients/upcoming-annual-renewals',
    label: 'Upcoming annual renewals',
  },
  {
    value: 'patients/upcoming-cancellations',
    label: 'Upcoming cancellations',
  },
  {
    value: 'patients/practice-performance',
    label: 'Practice performance',
  },
  {
    value: 'patients/plan-receipts-remittance',
    label: 'Plan receipts and remittance',
  },
  {
    value: 'memberships/lapsed-detail-report',
    label: 'Lapsed detail report',
  },
  {
    value: 'memberships/lapsed-summary-report',
    label: 'Lapsed summary report',
  },
  {
    value: 'memberships/subscriptions-summary-report',
    label: 'Subscriptions summary report',
  },
  {
    value: 'memberships/subscriptions-detail-report',
    label: 'Subscriptions detail report',
  },
  {
    value: 'memberships/outstanding-subscriptions-report',
    label: 'Outstanding subscriptions report',
  },
  {
    value: 'memberships/uninvoiced-subscriptions-report',
    label: 'Uninvoiced subscriptions report',
  },
  {
    value: 'memberships/uninvoiced-subscriptions-summary-report',
    label: 'Uninvoiced subscriptions summary report',
  },
  {
    value: 'memberships/age-related-changes-report',
    label: 'Age-related changes report',
  },
  {
    value: 'accounting/debtor-report',
    label: 'Debtor report',
  },
  {
    value: 'accounting/transaction-report',
    label: 'Transaction report',
  },
  {
    value: 'accounting/day-book-report',
    label: 'Day Book report',
  },
  {
    value: 'accounting/bordereau-report',
    label: 'Bordereau report',
  },
  {
    value: 'accounting/product-sales-report',
    label: 'Product sales by product code',
  },
  {
    value: 'accounting/sage-invoices-credits',
    label: 'SAGE Invoices & Credits',
  },
  {
    value: 'accounting/sage-receipts-refunds',
    label: 'SAGE Receipts & Refunds',
  },
];

export const CSV_REPORT_TYPE_REQUIRE_DATE = [
  'accounting/day-book-report',
  'accounting/product-sales-report',
  'accounting/transaction-report',
  'accounting/sage-invoices-credits',
  'accounting/sage-receipts-refunds',
  'patients/plan-receipts-remittance',
  'patients/patients-joining',
  'patients/patients-leaving',
  'memberships/lapsed-detail-report',
  'memberships/lapsed-summary-report',
];

export const CSV_REPORT_TYPE_REQUIRE_MONTH = [
  'memberships/age-related-changes-report',
];

export const IPLAN_CSV_REPORT_TYPE_OPTIONS = [
  {
    value: 'patients/patients-joining',
    label: 'Patients joining',
  },
  {
    value: 'memberships/outstanding-subscriptions-report',
    label: 'Outstanding subscriptions report',
  },
  {
    value: 'memberships/subscriptions-summary-report',
    label: 'Subscriptions summary report',
  },
  {
    value: 'memberships/subscriptions-detail-report',
    label: 'Subscriptions detail report',
  },
  {
    value: 'patients/upcoming-annual-renewals',
    label: 'Upcoming annual renewals',
  },
  {
    value: 'patients/upcoming-cancellations',
    label: 'Upcoming cancellations',
  },
];

import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, WrappedFieldArrayProps } from 'redux-form';

import AppButton from '^/common/app-button';
import PageSection from '^/common/page-section/page-section';
import { PageSubSectionBody } from '^/common/page-section/page-sub-section';
import { email, required } from '^/common/validation';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { StoreState } from '^/types';
import { NewPatient } from '../../types';
import {
  PUBLIC_GET_PRACTICE_CLINICIANS,
  PUBLIC_GET_PRACTICE_PLANS,
} from '../actions';

export type AddPatientFormProps = WrappedFieldArrayProps<Partial<NewPatient>> &
  ConnectedProps<typeof connector>;

const AddNewPatient: React.FC<AddPatientFormProps> = ({
  fields,
  plans,
  clinicians,
  loadingClinicians,
  loadingPlans,
}) => {
  const onAddPatient = () => fields.push({});
  const onRemovePatient = (index: number) => () => fields.remove(index);

  const getClinicians = () => {
    const options = clinicians?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
    if (options) {
      return [{ label: 'please choose...', value: '' }].concat(options);
    }
  };

  const getPlans = () => {
    const options = plans?.map(({ description, id }) => ({
      label: description,
      value: id,
    }));
    if (options) {
      return [{ label: 'please choose...', value: '' }].concat(options);
    }
  };

  const hasReachedThreshold =
    moment()
      .toDate()
      .getDate() >= 20;
  const initialDateValue = moment()
    .add(hasReachedThreshold ? 2 : 1, 'M')
    .startOf('M')
    .toDate();

  return (
    <>
      {fields.map((member, index) => (
        <PageSection
          className="add-patient-section"
          heading={`Patient ${index + 2}`}
          key={index}
        >
          <PageSection
            className="patient-signup-section"
            heading="Contact Details"
          >
            <FormGroup>
              <Field
                className="required"
                label="Prefix"
                name={`${member}.title`}
                component={RenderDropdown}
                validate={required}
                options={[
                  { label: 'Prefix', value: '' },
                  { label: 'Mr', value: 'Mr' },
                  { label: 'Mrs', value: 'Mrs' },
                  { label: 'Ms', value: 'Ms' },
                  { label: 'Miss', value: 'Miss' },
                  { label: 'Dr', value: 'Dr' },
                ]}
              />
              <Field
                className="required"
                component={RenderInputField}
                label="First Name"
                name={`${member}.first_name`}
                type="text"
                validate={[required]}
              />
              <Field
                className="required"
                component={RenderInputField}
                label="Surname"
                name={`${member}.last_name`}
                type="text"
                validate={[required]}
              />
              <Field
                className="required"
                component={RenderDateField}
                clearable={false}
                label="Date of Birth"
                name={`${member}.dob`}
                type="date"
                validate={[required]}
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={RenderInputField}
                label="Mobile Number"
                name={`${member}.mobile`}
                type="text"
              />
              <Field
                component={RenderInputField}
                label="Email Address"
                name={`${member}.email`}
                type="email"
                validate={[email]}
              />
            </FormGroup>
          </PageSection>
          <PageSection
            className="patient-signup-section"
            heading="Clinician Details"
            subHeading="This should be your primary clinician"
            subHeadingClass="text-caption-light"
          >
            {!loadingClinicians && clinicians && (
              <FormGroup>
                <Field
                  className="required"
                  label="Name"
                  name={`${member}.clinician`}
                  component={RenderDropdown}
                  options={getClinicians()}
                  type="text"
                  validate={[required]}
                />
              </FormGroup>
            )}
          </PageSection>
          <PageSection
            className="patient-signup-section"
            heading="Plan Details"
            subHeading="If you are unsure which plan is suitable for you, please contact
            the practice"
            subHeadingClass="text-caption-light"
          >
            {!loadingPlans && plans && (
              <FormGroup>
                <Field
                  className="required"
                  label="Plan Name"
                  name={`${member}.plan`}
                  component={RenderDropdown}
                  options={getPlans()}
                  type="text"
                  validate={[required]}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Field
                lockDate
                className="required"
                showMonthYearPicker
                label="First Payment Date"
                name={`${member}.start_date`}
                component={RenderDateField}
                clearable={false}
                validate={[required]}
                pickerProps={{
                  minDate: initialDateValue,
                }}
              />
            </FormGroup>
            <FormGroup className="text-caption-light margin-top-small margin-bottom-small">
              If todays date is on or beyond the 20th of the current month,
              please choose your start date as two months ahead e.g Today is the
              26th of March, so the earliest month you should select as the
              start date is May.
            </FormGroup>
          </PageSection>
          <PageSection>
            <PageSubSectionBody>
              <AppButton
                leftIcon="times"
                className="remove-patient"
                onClick={onRemovePatient(index)}
              >
                Remove Patient
              </AppButton>
            </PageSubSectionBody>
          </PageSection>
        </PageSection>
      ))}
      <PageSection className="add-patient">
        <PageSubSectionBody>
          <AppButton
            leftIcon="plus"
            disabled={fields.length === 3}
            onClick={onAddPatient}
          >
            Add Patient
          </AppButton>
        </PageSubSectionBody>
      </PageSection>
    </>
  );
};

export { AddNewPatient as TestableAddNewPatient };

export const mapState = (state: StoreState) => ({
  plans: state.publicPatient?.signupForm?.plans,
  clinicians: state.publicPatient?.signupForm?.clinicians,
  loadingPlans: isPending(state.responses, PUBLIC_GET_PRACTICE_PLANS),
  loadingClinicians: isPending(state.responses, PUBLIC_GET_PRACTICE_CLINICIANS),
});

const connector = connect(mapState);

export default connector(AddNewPatient);

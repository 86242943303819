import { anyPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import {
  clearAddresses,
  LOOKUP_ADDRESS,
  lookUpAddress,
  SELECT_ADDRESS,
  selectAddress,
} from '^/address/actions';
import { formatPostcode, validPostcode } from '^/address/helpers';
import { LoqateFindResponse } from '^/address/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { Loading } from '^/common/loading';
import { StoreState } from '^/types';

interface OwnProps {
  className?: string;
  label?: string;
  placeholder?: string;
  short?: boolean;
}

export type RenderPostcodeFieldProps = OwnProps &
  ConnectedProps<typeof connector> &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderPostcodeField extends React.PureComponent<
  RenderPostcodeFieldProps
> {
  public componentWillUnmount() {
    this.props.clearAddresses();
  }

  public render() {
    const {
      className,
      input,
      label,
      type,
      short,
      meta: { touched, error, warning, pristine, dirty },
      placeholder,
      suggestedAddresses,
      loading,
    } = this.props;

    const renderAddresses = () => {
      if (!suggestedAddresses) {
        if (loading) {
          return <Loading />;
        }

        return null;
      }

      if (!validPostcode(input.value) || suggestedAddresses.length === 0) {
        return (
          <div className="postcode-list">
            <h6>No addresses found.</h6>
          </div>
        );
      }

      return (
        <div className="postcode-list">
          <h6>Select an address</h6>
          <ul>
            {suggestedAddresses.map((item: LoqateFindResponse) => {
              return (
                <li
                  onClick={this.handleSelectAddress.bind(this, item)}
                  key={item.Id}
                  id={`select-address-${item.Id}`}
                >
                  {item.Text}, {item.Description}
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    return (
      <div
        id={`form-field-${input.name}`}
        className={classnames([
          'form-field',
          'form-field-postcode',
          className,
          { short, pristine, dirty },
        ])}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <div className="postcode-input">
          <input
            {...input}
            placeholder={placeholder}
            type={type}
            value={formatPostcode(input.value)}
            id="postcode-lookup"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          <AppButton onClick={this.handleSearch} loading={loading}>
            Search
          </AppButton>
        </div>

        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>

        {!error && renderAddresses()}
      </div>
    );
  }

  public handleChange = (event: { target: { value: string } }) => {
    if (validPostcode(event.target.value)) {
      this.props.lookUpAddress(event.target.value);
    }

    this.props.input.onChange(event);
  };

  public handleSearch = () => {
    this.props.lookUpAddress(this.props.input.value);
  };

  public handleSelectAddress = (address: LoqateFindResponse) => {
    this.props.selectAddress(address);
    this.props.clearAddresses();
  };

  public handleKeyPress = (event: { key: string; preventDefault(): void }) => {
    if (event.key === 'Enter' && !this.props.suggestedAddresses) {
      event.preventDefault();

      this.handleSearch();
    }
  };
}

export { RenderPostcodeField as TestableRenderPostcodeField };

export const mapState = (state: StoreState) => ({
  suggestedAddresses: state.suggestedAddresses,
  loading: anyPending(state.responses, [LOOKUP_ADDRESS, SELECT_ADDRESS]),
});

const connector = connect(mapState, {
  lookUpAddress,
  selectAddress,
  clearAddresses,
});

export default connector(RenderPostcodeField);

import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { scrollToFirstError } from '^/common/helper-functions';
import PageContent from '^/page-container/page-content';
import { paymentDetailActions } from '^/payment-details/actions';
import {
  isLoqateValidationError,
  isLoquateValidationResponse,
} from '^/payment-details/types';
import { StoreState } from '^/types';
import {
  CREATE_NEW_PATIENT,
  getPracticeClinicians as getPracticeCliniciansImport,
  getPracticePlans as getPracticePlansImport,
} from './actions';
import PatientAddSection from './section/patient-add';
import PatientConfirmationSection from './section/patient-confirmation';
import PatientDetailsSection from './section/patient-details';
import { NewPatientFormValues } from './types';

export interface OwnProps {
  onConfirm: () => void;
}

export type NewPatientFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<Partial<NewPatientFormValues>>;

const selector = formValueSelector('iplanNewPatientForm');
class NewPatientForm extends React.PureComponent<NewPatientFormProps> {
  public componentDidUpdate(prevProps: NewPatientFormProps) {
    if (this.props !== prevProps && this.props.selectedPracticeId) {
      this.props.getPracticePlans(this.props.selectedPracticeId);
      this.props.getPracticeClinicians(this.props.selectedPracticeId);
    }
  }

  public render() {
    const { handleSubmit, onConfirm, submitting } = this.props;
    const { loqateValidation, accountNumber, sortCode } = this.props;

    const validation = loqateValidation[`${accountNumber}+${sortCode}`];

    const validationErrors = [];

    if (isLoqateValidationError(validation)) {
      validationErrors.push(validation.Cause);
    } else if (isLoquateValidationResponse(validation)) {
      if (!validation.IsCorrect) {
        validationErrors.push('Sort code or account number invalid');
      }

      if (!validation.IsDirectDebitCapable) {
        validationErrors.push('Account is not direct debit capable');
      }
    }
    return (
      <PageContent page="new-patient">
        <Container>
          <Form className="new-patient-form" onSubmit={handleSubmit}>
            <PatientDetailsSection />
            <PatientAddSection />
            <PatientConfirmationSection />
            <div className="new-patient-submit">
              <AppButton type="button" onClick={onConfirm} loading={submitting}>
                Save & Submit
              </AppButton>
            </div>
            <ErrorRenderer
              actions={[...paymentDetailActions, CREATE_NEW_PATIENT]}
              fields={['non_field_errors']}
              error={validationErrors}
              showStatusErrors
              centered
            />
          </Form>
        </Container>
      </PageContent>
    );
  }
}

export { NewPatientForm as TestableNewPatientForm };

export const mapState = (state: StoreState) => {
  return {
    loqateValidation: state.loqateValidation,
    accountNumber: selector(state, 'account_number'),
    sortCode: selector(state, 'sort_code'),
    selectedPracticeId: state.iplan?.selectedPracticeId,
  };
};

const connector = connect(mapState, {
  getPracticeClinicians: getPracticeCliniciansImport,
  getPracticePlans: getPracticePlansImport,
});

export default reduxForm<Partial<NewPatientFormValues>, OwnProps>({
  form: 'iplanNewPatientForm',
  enableReinitialize: true,
  onSubmitFail: scrollToFirstError,
})(connector(NewPatientForm));

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { email, required } from '^/common/validation';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';

class Contact extends React.PureComponent {
  public render() {
    return (
      <>
        <PageSection className="new-patient-section" heading="Contact Details">
          <FormGroup>
            <Field
              className="required"
              label="Prefix"
              name="title"
              component={RenderDropdown}
              validate={required}
              options={[
                { label: 'Prefix', value: '' },
                { label: 'Mr', value: 'Mr' },
                { label: 'Mrs', value: 'Mrs' },
                { label: 'Ms', value: 'Ms' },
                { label: 'Miss', value: 'Miss' },
                { label: 'Dr', value: 'Dr' },
              ]}
            />
            <Field
              className="required"
              component={RenderInputField}
              label="First Name"
              name="first_name"
              type="text"
              validate={required}
            />
            <Field
              className="required"
              component={RenderInputField}
              label="Surname"
              name="last_name"
              type="text"
              validate={required}
            />
            <Field
              className="required"
              component={RenderDateField}
              clearable={false}
              label="Date of birth"
              name="dob"
              type="date"
              validate={required}
            />
          </FormGroup>
          <FormGroup>
            <Field
              className="required"
              component={RenderInputField}
              label="Mobile Number"
              name="mobile"
              type="text"
              validate={required}
            />
            <Field
              className="required"
              component={RenderInputField}
              label="Email Address"
              name="email"
              type="email"
              validate={[email, required]}
            />
          </FormGroup>
        </PageSection>
      </>
    );
  }
}

export { Contact as TestableContact };

export default Contact;

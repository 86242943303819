import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

interface OwnProps {
  label?: string;
  disabled?: boolean;
  vertical?: boolean;
}

export type Props = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderCheckBox extends React.PureComponent<Props> {
  public render() {
    const {
      input,
      label,
      meta: { dirty, pristine, error, submitFailed },
      disabled,
      vertical,
      className,
    } = this.props;

    return (
      <div
        id={`form-field-${input.name}`}
        className={classnames([
          className,
          'form-field',
          'form-field-checkbox',
          error && submitFailed && 'error',
          { dirty, pristine, disabled, vertical },
        ])}
      >
        <input
          {...input}
          id={input.name}
          disabled={disabled}
          type="checkbox"
          checked={input.value}
        />
        {error && submitFailed && <span className="error">Required</span>}
        {label && <label htmlFor={input.name}>{label}</label>}
      </div>
    );
  }
}

export default RenderCheckBox;

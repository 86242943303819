import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { required } from '^/common/validation';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderSortCode from '^/form-helpers/render-sort-code';
import { StoreState } from '^/types';

export type PaymentProps = ConnectedProps<typeof connector>;

const selector = formValueSelector('iplanNewPatientForm');

export class Payment extends React.Component<PaymentProps> {
  public render() {
    return (
      <PageSection className="new-patient-section" heading="Payment Details">
        <FormGroup className="practice-details-group">
          <Field
            className="required"
            label="Payment method"
            name="payment_method"
            component={RenderDropdown}
            options={[
              { label: 'Direct Debit', value: 'Direct Debit', selected: true },
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            className="required"
            label="Account holder name"
            name="account_name"
            component={RenderInputField}
            type="text"
            validate={[required]}
          />
          <Field
            className="required"
            label="Sort code"
            placeholder="XX-XX-XX"
            name="sort_code"
            component={RenderSortCode}
            type="text"
            validate={[required]}
          />
          <Field
            className="required"
            label="Account number"
            placeholder="XXXXXXXX"
            name="account_number"
            component={RenderInputField}
            type="text"
            validate={[required]}
          />
        </FormGroup>
      </PageSection>
    );
  }
}

export const mapState = (state: StoreState) => ({
  loqateValidation: state.loqateValidation,
  accountNumber: selector(state, 'account_number'),
  sortCode: selector(state, 'sort_code'),
});

const connector = connect(mapState, {});

export default connector(Payment);

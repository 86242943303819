import moment, { Moment } from 'moment';
import { FormErrors } from 'redux-form';

import { getAddressPhoneNumber, sortContactAddresses } from '^/address/helpers';
import { CompanyProfile } from '^/contacts/companies/types';
import { PersonProfile } from '^/contacts/persons/types';
import { Contact, ContactResponse, ContactStatus } from '^/contacts/types';
import { BulkReportParams } from '^/routines/types';
import { SortList } from '^/sorts/types';
import { ContactType, QueryParams } from '^/types';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DEFAULT_DATE_PICKER_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_PICKER_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export const formatDate = (
  dateToFormat: string | Moment | null | undefined,
  defaultValue = '-',
  dateFormat = DEFAULT_DATE_FORMAT
): string => {
  if (!dateToFormat) {
    return defaultValue;
  }
  const dateToReturn = moment(dateToFormat, moment.ISO_8601).format(dateFormat);

  if (dateToReturn === 'Invalid date') {
    return '-';
  }

  return dateToReturn;
};

export const formatDateTime = (dateToFormat?: string | Moment): string =>
  dateToFormat
    ? moment(dateToFormat, moment.ISO_8601).format(DEFAULT_DATE_TIME_FORMAT)
    : '-';

export const formatDateCalendar = (dateToFormat: string | Moment): string => {
  moment.locale('en-gb');
  return dateToFormat ? moment(dateToFormat, moment.ISO_8601).calendar() : '-';
};

export const formatDateFromNow = (dateToFormat: string | Moment): string =>
  dateToFormat ? moment(dateToFormat, moment.ISO_8601).fromNow() : '-';

export const generateQueryString = (params: QueryParams) => {
  const queries = Object.keys(params).map(key =>
    params[key] ? `${key}=${String(params[key])}` : undefined
  );

  return queries.filter(Boolean).length >= 1
    ? '?'.concat(encodeURI(queries.filter(Boolean).join('&')))
    : '';
};

export const generateParamsSummary = (
  params: BulkReportParams | null
): string => {
  if (params && params.month) {
    return formatDate(params.month, 'unknown month', 'MMMM YYYY');
  } else if (params && params.start_date && params.end_date) {
    return `date range ${formatDate(
      params.start_date,
      'unknown start date'
    )} to ${formatDate(params.end_date, 'unknown end date')}`;
  }

  return 'unknown date';
};

export const getPersonDob = (contact: Contact): string =>
  contact.person && contact.person.dob ? formatDate(contact.person.dob) : '-';

export const getContactCrmId = (
  contact?: Contact | null,
  defaultValue: string = '-'
): string => (contact && contact.crm_id ? contact.crm_id : defaultValue);

export const getContactDob = (
  contact?: Contact | null,
  defaultValue: string = '-'
) => {
  if (!contact) {
    return defaultValue;
  }

  if (contact.person && contact.person.dob) {
    return formatDate(contact.person.dob);
  }

  return defaultValue;
};

export const getContactName = (
  contact?: Contact | null,
  title?: boolean,
  defaultValue: string = '-'
): string => {
  if (!contact) {
    return defaultValue;
  }

  const archived =
    contact.status === ContactStatus.Archived ? ' (Archived)' : '';

  if (contact.person) {
    return contact.person.title && title
      ? `${contact.person.title} ${contact.person.first_name} ${contact.person.last_name}${archived}`
      : `${contact.person.first_name} ${contact.person.last_name}${archived}`;
  }

  if (contact.company) {
    return `${contact.company.name}${archived}`;
  }

  return defaultValue;
};

export const getContactSurname = (
  contact?: Contact | null,
  title?: boolean,
  defaultValue: string = '-'
): string => {
  if (!contact) {
    return defaultValue;
  }

  const archived =
    contact.status === ContactStatus.Archived ? ' (Archived)' : '';

  if (contact.person) {
    return contact.person.title && title
      ? `${contact.person.title} ${contact.person.last_name}${archived}`
      : `${contact.person.last_name}${archived}`;
  }

  if (contact.company) {
    return `${contact.company.name}${archived}`;
  }

  return defaultValue;
};

export const getContactPhone = (contact: Contact): string | null => {
  if (contact.person && contact.person.mobile) {
    return contact.person.mobile;
  }

  const sortedAddresses = sortContactAddresses(contact);

  for (const address of sortedAddresses) {
    const phoneNumber = getAddressPhoneNumber(address);

    if (phoneNumber) {
      return phoneNumber;
    }
  }

  return null;
};

export const getCompanyWebsite = (contact: CompanyProfile | null): string => {
  if (!contact || !contact.website) {
    return 'No website provided.';
  }

  return contact.website;
};

export const getPersonDetails = (
  contact?: ContactResponse
): PersonProfile | undefined => {
  if (!contact || !contact.person) {
    return undefined;
  }

  if (contact.person) {
    return contact.person;
  }
};

export const getCompanyDetails = (
  contact?: ContactResponse
): CompanyProfile | undefined => {
  if (!contact || !contact.company) {
    return undefined;
  }

  if (contact.company) {
    return contact.company;
  }
};

export const getContactEmail = (contact: Contact): string => {
  if (contact.person) {
    return contact.person.email ? contact.person.email : '';
  }

  if (contact.company) {
    return contact.company.email ? contact.company.email : '';
  }

  return '';
};

export const getContactCommsMethod = (contact: ContactResponse): string => {
  if (contact.person) {
    return contact.person.communication_method.toLowerCase();
  }

  if (contact.company) {
    return contact.company.communication_method.toLowerCase();
  }

  return 'Contact not found.';
};

export const isCompanyOrPerson = (
  contact: Contact,
  type: 'Company' | 'Person'
) => (type === 'Company' ? contact.company : contact.person);

export const isContactType = (contact: Contact, type: ContactType) =>
  contact.type === type;

export const toggleSortFor = (
  orderBy: string,
  appliedSorts: SortList,
  setProps: (appliedSorts: { appliedSorts: SortList }) => void
) => {
  switch (appliedSorts[orderBy]) {
    case 'ASC':
      setProps({
        appliedSorts: { ...appliedSorts, [orderBy]: 'DESC' },
      });
      break;
    case 'DESC':
      setProps({
        appliedSorts: { ...appliedSorts, [orderBy]: null },
      });
      break;
    default:
      setProps({
        appliedSorts: { ...appliedSorts, [orderBy]: 'ASC' },
      });
  }
};

export const isObjectEmpty = (obj?: object | null) =>
  !obj || (Object.entries(obj).length === 0 && obj.constructor === Object);

export const parseNumberField = (value: string) =>
  isNaN(parseFloat(value)) ? null : parseFloat(value);

export const generateContactWarnings = (
  surnameCount: number,
  emailCount: number
) => {
  const warnings = [];

  if (surnameCount === 1) {
    warnings.push(
      'There is already a contact with this first name, surname and DOB.'
    );
  } else if (surnameCount > 1) {
    warnings.push(
      `There are already ${surnameCount} contacts with this first name, surname and DOB.`
    );
  }

  if (emailCount === 1) {
    warnings.push('There is already a contact with this email.');
  } else if (emailCount > 1) {
    warnings.push(`There are already ${emailCount} contacts with this email.`);
  }

  return warnings;
};

export const scrollToFirstError = (errors: FormErrors<string>) => {
  const firstError = Object.keys(errors)[0];
  const el = document.querySelector(`[name="${firstError}"]`);

  if (el === null) {
    return;
  }

  const position =
    el.getBoundingClientRect().top + document.documentElement.scrollTop;
  const offset = -100;

  window.scrollTo({
    top: position + offset,
    behavior: 'smooth',
  });
};

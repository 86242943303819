import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { loggedInUser } from '^/middleware/check-for-logout';
import {
  GET_PLAN_SUBSCRIPTIONS,
  getPlanSubscriptions,
} from '^/plans/subscriptions/actions';
import { PlanSubscriptionResponse } from '^/plans/subscriptions/types';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import { UserRole } from '../../admin/users/types';
import { getLoggedInUser } from '../../login/actions';
import { formatFeeAmount, getPlanName } from '../helpers';
import { isSubscriptionActive } from './helpers';

interface OwnProps {
  contactId: string;
}

export type Props = OwnProps & ConnectedProps<typeof connector>;

export class PlanSubscriptionsTable extends React.Component<Props> {
  public componentDidMount() {
    const { loading, contactId } = this.props;
    if (!loading && contactId) {
      this.props.getPlanSubscriptions(contactId);
    }

    if (!loggedInUser || !loggedInUser.id) {
      this.props.getLoggedInUser();
    }
  }

  public render() {
    const { contactId, subscriptions } = this.props;

    const currentPageSubscriptions = getCurrentPage(subscriptions);

    return (
      <PaginatedTable
        paginatedData={subscriptions}
        changePage={this.changePage}
        loading={this.props.loading}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableCell className="align-right">Start Date</TableCell>
            <TableCell className="align-right">Date Added</TableCell>
            <TableCell className="align-right">Fee</TableCell>
            <TableCell className="align-right">Joining Fee</TableCell>
            <TableCell className="align-right">Discount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPageSubscriptions
            .filter(
              (sub1, sub1Index) =>
                currentPageSubscriptions.findIndex(
                  sub2 => sub2.id === sub1.id
                ) === sub1Index
            )
            .map(subscription => (
              <ItemRow
                key={subscription.id}
                item={subscription}
                onClick={
                  loggedInUser?.role !== UserRole.PracticeAdminLevel &&
                  loggedInUser?.role !== UserRole.PracticeUserLevel
                    ? this.handleClick
                    : undefined
                }
              >
                <TableHeader title={getPlanName(subscription.plan_detail)}>
                  {subscription.plan_detail?.description}
                </TableHeader>
                <TableCell className="align-right">
                  {subscription.start_date}
                </TableCell>
                <TableCell className="align-right">
                  {
                    subscription.contacts?.filter(
                      subscriptionContact =>
                        (subscriptionContact.contact = contactId)
                    )[0]?.start_date
                  }
                </TableCell>
                <TableCell className="align-right">
                  {subscription.calculated_fees &&
                    formatFeeAmount(subscription.calculated_fees.fee)}
                </TableCell>
                <TableCell className="align-right">
                  {subscription.calculated_fees &&
                    formatFeeAmount(subscription.calculated_fees.joining_fee)}
                </TableCell>
                <TableCell className="align-right">
                  {subscription.calculated_fees &&
                    formatFeeAmount(
                      subscription.calculated_fees.discount,
                      undefined,
                      undefined,
                      true
                    )}
                </TableCell>
                {this.renderStatusCell(subscription)}
              </ItemRow>
            ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (subscription: PlanSubscriptionResponse) => {
    this.props.push(
      `/subscriptions/${subscription.id}?patient=${this.props.contactId}`
    );
  };

  public changePage = (page: number, pageSize: number) => {
    this.props.getPlanSubscriptions(this.props.contactId, page, pageSize);
  };

  private renderStatusCell(subscription: PlanSubscriptionResponse) {
    const { contactId } = this.props;
    const isActive = isSubscriptionActive(subscription, contactId);
    const error = subscription.suspended || !isActive;
    const label = !subscription.id
      ? 'Pending'
      : subscription.suspended
      ? 'Suspended'
      : isActive
      ? 'Active'
      : 'Lapsed';

    return <TableCell className={classNames({ error })}>{label}</TableCell>;
  }
}

export { PlanSubscriptionsTable as TestablePlanSubscriptionsTable };

export const mapState = (state: StoreState, props: OwnProps) => ({
  subscriptions: state.planSubscriptions[props.contactId],
  loading: anyPending(state.responses, [GET_PLAN_SUBSCRIPTIONS]),
  loggedInUser: state.loggedInUser,
});

const connector = connect(mapState, {
  getPlanSubscriptions,
  push,
  getLoggedInUser,
});

export default connector(PlanSubscriptionsTable);

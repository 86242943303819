import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import PageSection from '^/common/page-section/page-section';
import { postcode, required } from '^/common/validation';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderPostcodeField from '^/form-helpers/render-postcode-field';

class Address extends React.PureComponent {
  public render() {
    return (
      <PageSection
        className="patient-signup-section"
        heading="Address Details"
        subHeading="This is the primary address of the patient paying for the plans. This
        is required for your iPlan welcome pack and contact by post"
        subHeadingClass="text-caption-light"
      >
        <FormGroup>
          <Field
            className="required"
            label="Postcode"
            name="postcode"
            component={RenderPostcodeField}
            type="text"
            validate={[required, postcode]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            className="required"
            label="Address"
            name="street_address"
            component={RenderInputField}
            type="text"
            validate={[required]}
          />
          <Field
            className="required"
            label="City"
            name="city"
            component={RenderInputField}
            type="text"
            validate={[required]}
            short
          />
        </FormGroup>
        <FormGroup>
          <Field
            className="required"
            label="County"
            name="county"
            component={RenderInputField}
            type="text"
            validate={[required]}
          />
          <Field
            className="required"
            label="Country"
            name="country"
            component={RenderInputField}
            type="text"
            validate={[required]}
          />
        </FormGroup>
        <ErrorRenderer fields={['non_field_errors']} showStatusErrors />
      </PageSection>
    );
  }
}

export { Address as TestableAddress };

export default Address;
